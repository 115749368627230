(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/winning-ticket.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/winning-ticket.js');
"use strict";

setGlobal('svs.supportern.components.composites.WinningTicket', () => {
  const {
    useAtom
  } = jotai;
  const {
    Avatar,
    Ticket
  } = svs.supportern.components;
  const {
    ticketByIdAtom
  } = svs.supportern.atoms;
  const formatCurrency = svs.utils.format.Currency;
  const [ticket] = useAtom(ticketByIdAtom);
  const {
    draw
  } = ticket;
  const {
    ownerTeam,
    results
  } = draw;
  const {
    poolSize: {
      amount: poolAmount
    }
  } = results.stats;
  const {
    amount
  } = ticket.prize;
  const winAmount = amount === null || amount === void 0 ? void 0 : amount.amount;
  const {
    number
  } = draw.results.prizeCategories[0].winners[0].winnerData;
  const Won = () => React.createElement(React.Fragment, null, React.createElement("div", {
    className: "f-550 padding-bottom-half padding-top-half"
  }, "nr", ' ', React.createElement("span", {
    className: "f-800"
  }, "".concat(number).padStart(6, '0'))), React.createElement("div", {
    className: "bg-grey-300 margin-bottom-half",
    style: {
      height: '1px'
    }
  }), React.createElement("span", null, "Vinst"), React.createElement("div", {
    className: "f-800 padding-top-half"
  }, React.createElement("span", null, formatCurrency(winAmount)), React.createElement("span", {
    className: "f-400"
  }, "\xA0kr")));
  const Loss = () => React.createElement(React.Fragment, null, React.createElement("span", {
    className: "block f-300"
  }, "Grattis"), React.createElement("span", {
    className: "block f-300 f-bold"
  }, ownerTeam.name), React.createElement("span", {
    className: "block f-300"
  }, "som fick"), React.createElement("span", {
    className: "block f-800 padding-half"
  }, formatCurrency(poolAmount), ' ', React.createElement("span", {
    className: "f-400"
  }, "kr")));
  return React.createElement("div", {
    className: "ticket-double-size-container"
  }, React.createElement(Ticket, {
    className: "centered ticket-double-size filter-shadow-100"
  }, React.createElement("div", {
    className: "supportern-ticket-top padding-1"
  }, React.createElement(Avatar, null)), React.createElement("div", {
    className: "padding-half"
  }, winAmount ? React.createElement(Won, null) : React.createElement(Loss, null))));
});

 })(window);