(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/box-loader.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/box-loader.js');
"use strict";


const {
  useEffect,
  useRef
} = React;
setGlobal('svs.supportern.components.BoxLoader', () => {
  const boxRef = useRef();
  useEffect(() => {
    const loader = new svs.ui.Loader(boxRef.current).show(300, 'inverted');
    return () => {
      loader.hide();
    };
  }, []);
  return React.createElement("div", {
    className: "relative",
    ref: boxRef,
    style: {
      height: 300
    }
  });
});

 })(window);