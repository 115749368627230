(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/loader.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/loader.js');
"use strict";


const {
  useEffect
} = React;
setGlobal('svs.supportern.components.Loader', () => {
  useEffect(() => {
    svs.ui.loader.show();
    return () => {
      svs.ui.loader.hide();
    };
  }, []);
  return null;
});

 })(window);