(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/atoms/team-atoms.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/atoms/team-atoms.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const {
  apiController
} = svs.supportern.utils;
const {
  TEAM_ID,
  TEAMS,
  TEAM_ID_FAVORITE
} = svs.supportern.utils.rafflePaths;
const {
  atom
} = jotai;
const {
  atomsWithQuery,
  atomsWithMutation,
  queryClientAtom
} = jotaiTanstackQuery;
const {
  hiddenTeams = []
} = svs.supportern.data.config;
const updateDraws = _ref => {
  let {
    draws,
    isFavorite,
    team
  } = _ref;
  return draws.map(d => _objectSpread(_objectSpread({}, d), {}, {
    ownerTeam: _objectSpread(_objectSpread({}, d.ownerTeam), {}, {
      favorite: d.ownerTeam.id === team.id ? isFavorite : d.ownerTeam.favorite
    })
  }));
};
const updateTeams = _ref2 => {
  let {
    teams,
    isFavorite,
    team
  } = _ref2;
  return teams.map(t => _objectSpread(_objectSpread({}, t), {}, {
    favorite: t.id === team.id ? isFavorite : t.favorite
  }));
};
const updateFavorites = _ref3 => {
  let {
    teams,
    isFavorite,
    team
  } = _ref3;
  return isFavorite ? [...teams, _objectSpread(_objectSpread({}, team), {}, {
    favorite: true
  })] : teams.filter(t => t.id !== team.id);
};
const updateQueries = _ref4 => {
  let {
    isFavorite,
    queryClient,
    team
  } = _ref4;
  queryClient.setQueriesData(['teams'], teams => updateTeams({
    teams,
    isFavorite,
    team
  }));
  queryClient.setQueriesData(['draws'], draws => updateDraws({
    draws,
    isFavorite,
    team
  }));
  if (queryClient.getQueryData(['teams', 'favorites=true'])) {
    queryClient.setQueryData(['teams', 'favorites=true'], teams => updateFavorites({
      teams,
      isFavorite,
      team
    }));
  }
};
const teamAtoms = {
  teamsAtom: atom(),
  teamAtom: atom(),
  teamIdAtom: atom(),
  teamsFilterAtom: atom(),
  teamsByFilterAtom: atomsWithQuery(get => ({
    queryKey: ['teams', get(teamAtoms.teamsFilterAtom)],
    queryFn: async _ref5 => {
      let {
        queryKey: [, teamsFilter]
      } = _ref5;
      if (!teamsFilter) {
        svs.supportern.logger.warn('teamsFilter is undefined!');
      }
      const {
        items
      } = await apiController({
        path: "".concat(TEAMS, "?").concat(new URLSearchParams(teamsFilter).toString(), "&pageSize=50")
      });
      return items.filter(_ref6 => {
        let {
          id
        } = _ref6;
        return hiddenTeams.reduce((acc, _ref7) => {
          let {
            teamId
          } = _ref7;
          return [...acc, teamId.toLowerCase()];
        }, []).indexOf(id.toLowerCase()) === -1;
      });
    }
  }))[0],
  teamByIdAtom: atomsWithQuery(get => ({
    queryKey: ['teams', get(teamAtoms.teamIdAtom)],
    queryFn: async _ref8 => {
      let {
        queryKey: [, teamId]
      } = _ref8;
      if (!teamId) {
        svs.supportern.logger.warn('asyncTeamAtom requires a teamId Provider');
        return [];
      }
      try {
        const team = await apiController({
          path: TEAM_ID.replace(':id', teamId)
        });
        return [team];
      } catch (e) {
        return [{}];
      }
    }
  }))[0],
  favoriteMutationAtom: atomsWithMutation(get => ({
    mutationKey: ['teams'],
    mutationFn: async _ref9 => {
      let {
        team
      } = _ref9;
      const method = team.favorite ? 'DELETE' : 'PUT';
      svs.supportern.logger.info("".concat(method, " favorite team with id: ").concat(team.id));
      return apiController({
        path: TEAM_ID_FAVORITE.replace(':id', team.id),
        options: {
          method
        }
      });
    },
    onMutate: _ref10 => {
      let {
        team
      } = _ref10;
      updateQueries({
        team,
        isFavorite: !team.favorite,
        queryClient: get(queryClientAtom)
      });
      return {
        isFavorite: !team.favorite
      };
    },
    onError: (_error, _ref11) => {
      let {
        team
      } = _ref11;
      svs.supportern.logger.error('Favorite call failed');
      updateQueries({
        team,
        isFavorite: !team.favorite,
        queryClient: get(queryClientAtom)
      });
    },
    onSuccess: () => {
      svs.supportern.logger.info('Favorite call successful');
    }
  }))[1]
};
setGlobal('svs.supportern.atoms', teamAtoms);

 })(window);