(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/routes/play/start.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/routes/play/start.js');
"use strict";


const {
  WidgetArea
} = svs.components.tb_ui_react.widget_area;
setGlobal('svs.supportern.routes', {
  START_PATH: '/',
  Start: () => React.createElement(WidgetArea, {
    areaName: "startWidget",
    className: "full-width-widget"
  })
});

 })(window);