(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/logged-out-favorites.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/logged-out-favorites.js');
"use strict";


const {
  standardCardLayout
} = svs.supportern.utils.tools;
const {
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const Button = svs.ui.ReactButton;
const Icon = svs.ui.ReactIcon;
setGlobal('svs.supportern.components.composites.LoggedOutFavorites', () => {
  const handleButtonClick = () => svs.components.customer_login.login();
  return React.createElement(React.Fragment, null, React.createElement(GridRow, null, React.createElement(GridCol, null, React.createElement("div", {
    className: "u-flex u-flex-justify-content u-flex-align-items f-center u-flex-column margin-2"
  }, React.createElement(Icon, {
    icon: "favorites",
    size: "900"
  }), React.createElement("h2", {
    className: "margin-2 f-700"
  }, " Logga in f\xF6r att se eller v\xE4lja dina favoritlag")))), React.createElement(GridRow, {
    className: "u-flex u-flex-justify-content-center padding-top-1 padding-bottom-1"
  }, React.createElement(GridCol, standardCardLayout, React.createElement(Button, {
    block: true,
    className: "margin-bottom-2 margin-top-1",
    focus: true,
    inverted: true,
    onClick: handleButtonClick,
    size: 300
  }, "Logga in"))));
});

 })(window);