(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/league.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/league.js');
"use strict";


const {
  ExpandableBox,
  Header: BoxHeader,
  Body: BoxBody
} = svs.components.expandableBox;
const {
  getIcon
} = svs.supportern.utils.tools;
const {
  useAtomValue
} = jotai;
const {
  BoxLoader
} = svs.supportern.components;
const {
  competitionAtom
} = svs.supportern.atoms;
const {
  Suspense
} = React;
const League = _ref => {
  let {
    children,
    expandable = true,
    isExpanded = false
  } = _ref;
  const competition = useAtomValue(competitionAtom);
  const {
    name,
    branding = {}
  } = competition;
  const Wrapper = expandable ? BoxHeader : React.Fragment;
  return React.createElement(ExpandableBox, {
    isExpanded: isExpanded
  }, isExpanded => React.createElement(React.Fragment, null, (() => {
    if (isExpanded) {
      svs.supportern.utils.tracking.onTabLeague(competition.name);
    }
  })(), React.createElement(Wrapper, null, React.createElement("div", {
    className: "panel-heading ".concat(expandable ? 'pointer' : '', " margin-2")
  }, React.createElement("div", {
    className: "row u-flex-wrap u-flex-justify-content-center u-flex"
  }, branding.logo && React.createElement("img", {
    className: "league-img",
    src: getIcon(branding.logo)
  })), React.createElement("div", {
    className: "relative u-flex-wrap u-flex-justify-content-center u-flex u-flex-align-items-center"
  }, React.createElement("div", {
    className: "f-600 f-bold padding-top-1"
  }, name), expandable && React.createElement("i", {
    className: "icon-default icon-menu-".concat(isExpanded ? 'up' : 'down', " expandable-box-icon absolute league-toggle-arrow")
  })))), React.createElement(BoxBody, null, React.createElement(Suspense, {
    fallback: React.createElement(BoxLoader, null)
  }, children, " "))));
};
setGlobal('svs.supportern.components.composites.League', League);

 })(window);