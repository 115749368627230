(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/utils/scroll-to-top.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/utils/scroll-to-top.js');
"use strict";


const {
  useEffect
} = React;
const {
  useLocation
} = ReactRouterDOM;
setGlobal('svs.supportern.utils.ScrollToTop', () => {
  const {
    pathname
  } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
});

 })(window);