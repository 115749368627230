(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/utils/api-controller.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/utils/api-controller.js');
"use strict";

var _svs$supportern$data, _svs$supportern;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

let numberOf401 = 0;
const {
  config
} = (_svs$supportern$data = (_svs$supportern = svs.supportern) === null || _svs$supportern === void 0 ? void 0 : _svs$supportern.data) !== null && _svs$supportern$data !== void 0 ? _svs$supportern$data : {};
const {
  clientId,
  apiLocation
} = config !== null && config !== void 0 ? config : {};
const {
  getErrorMessage
} = svs.tur.apierrors;
const doLogout = svs.components.Storage.browser.get('wasLoggedIn', 'supportern') && !svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
class APIError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}
if (doLogout) {
  (async () => {
    const resp = await fetch("".concat(apiLocation, "/identity/web/v1/sessions/current"), {
      method: 'DELETE',
      credentials: 'include'
    });
    if (resp.status === 204 || resp.status === 403) {
      svs.components.Storage.browser.set('wasLoggedIn', 'supportern', false);
    }
  })();
}
const showErrorDialog = async resp => {
  const defaultError = 'ERROR';
  const {
    errorMessage,
    errorTitle
  } = await getErrorMessage(resp === null || resp === void 0 ? void 0 : resp.code, undefined, defaultError);
  if (errorMessage !== defaultError) {
    svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
      branding: svs.ui.dialog.branding.TUR,
      type: svs.ui.dialog.type.ERROR,
      title: errorTitle,
      message: [{
        type: svs.ui.dialog.message.TEXT,
        text: errorMessage
      }],
      actions: [{
        title: 'OK'
      }]
    }));
  } else {
    svs.supportern.logger.warn("Generic error, supressing: ".concat(resp === null || resp === void 0 ? void 0 : resp.status));
  }
};
setGlobal('svs.supportern.utils.showErrorDialog', showErrorDialog);
let isWaiting = false;
const login = async () => {
  if (!isWaiting) {
    if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      svs.components.customer_login.login();
      await (async () => new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 10000);
      }));
    }
    isWaiting = true;
    const resp = await fetch("".concat(apiLocation, "/identity/web/v1/sessions/state"));
    if (resp.ok) {
      const stateResp = await resp.json();
      const {
        state
      } = stateResp;
      const codeResp = await svs.core.jupiter.callAsync({
        method: 'GET',
        path: '/einstant/1/accesscode',
        data: {
          clientId,
          state,
          scope: 'playerid playersession urn:svsauth:tpproxy:aras'
        }
      });
      if (codeResp.response) {
        const {
          accessCode,
          state
        } = codeResp.response;
        const resp = await fetch("".concat(apiLocation, "/identity/web/v1/sessions/token/exchange"), {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/x-www-form-urlencoded'
          },
          body: JSON.stringify({
            code: accessCode,
            state
          })
        });
        if (resp.ok) {
          isWaiting = false;
          svs.components.Storage.browser.set('wasLoggedIn', 'supportern', true);
          return true;
        }
        return false;
      }
    }
  } else {
    await new Promise(resolve => {
      const interval = setInterval(() => {
        if (!isWaiting) {
          clearInterval(interval);
          resolve(true);
        }
      }, 10);
    })();
    return true;
  }
};
const RAFFLE_ROOT = "".concat(apiLocation, "/raffle-associations");
const RAFFLE_PATHS = {
  SPORTS: '/web/v1/sports',
  COMPETITIONS: '/web/v1/competitions',
  COMPETITION_ID: '/web/v1/competitions/:id',
  ASSOCIATIONS: '/web/v1/associations',
  ASSOCIATION_ID: '/web/v1/associations/:id',
  TEAMS: '/web/v1/teams',
  TEAM_ID: '/web/v1/teams/:id',
  TEAM_ID_FAVORITE: '/web/v1/teams/:id/favorite',
  FAVORITE: '/web/v1/teams/:id/favorite',
  DRAWS: '/web/v1/draws',
  TICKETS: '/web/v1/tickets',
  TICKET_ID: '/web/v1/tickets/:id',
  TICKET_ID_TOKEN: '/web/v1/tickets/public?token=:token',
  TICKET_PRIZE_CHECKED: '/web/v1/tickets/:id/present-results',
  TICKET_PRIZE_CHECKED_TOKEN: '/web/v1/tickets/public/present-results?token=:token'
};
Object.keys(RAFFLE_PATHS).forEach(key => {
  RAFFLE_PATHS[key] = RAFFLE_ROOT + RAFFLE_PATHS[key];
});
setGlobal('svs.supportern.utils.rafflePaths', RAFFLE_PATHS);
const POOL_ROOT = "".concat(apiLocation, "/raffle-pooled");
const POOL_PATHS = {
  BUY_TICKETS: '/web/v1/tickets',
  DRAW_STATS: '/web/v1/stats/draws/active'
};
Object.keys(POOL_PATHS).forEach(key => {
  POOL_PATHS[key] = POOL_ROOT + POOL_PATHS[key];
});
setGlobal('svs.supportern.utils.poolPaths', POOL_PATHS);
setGlobal('svs.supportern.utils.apiController', async params => {
  const {
    path,
    query = '',
    options = {
      method: 'GET'
    },
    suppressErrors = false
  } = params;
  const queryPath = "".concat(path).concat(query.length ? "?".concat(query) : '');
  svs.supportern.logger.debug("Fetching url: ".concat(queryPath));
  const resp = await fetch(queryPath, _objectSpread({
    credentials: 'include'
  }, options));
  if (!resp.ok) {
    if (resp.status === 401) {
      if (numberOf401 < 2) {
        numberOf401++;
        await login();
        return svs.supportern.utils.apiController(params);
      }
      if (!suppressErrors) {
        const res = await resp.json();
        showErrorDialog(res);
      }
      numberOf401 = 0;
      throw new Error("API responded with code ".concat(resp.status));
    }
    numberOf401 = 0;
    let statusTitle;
    let code;
    try {
      const parsed = await resp.json();
      statusTitle = parsed.statusTitle;
      code = parsed.code;
      resp.parsed = parsed;
      if (!suppressErrors) {
        showErrorDialog(parsed);
      }
    } catch (e) {}
    svs.supportern.logger.error("API error title: ".concat(statusTitle, "  code: ").concat(code, " from: ").concat(queryPath), resp.status);
    throw new APIError("API responded with code ".concat(resp.status), resp);
  }
  if (/get/i.test(options.method) || options.shouldReturnData) {
    const returnObj = await resp.json();
    returnObj.serverTime = resp.headers.get('x-server-time');
    return returnObj;
  }
  return resp.ok;
});

 })(window);