(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/team-list.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/team-list.js');
"use strict";


const {
  AtomProvider,
  TeamItem
} = svs.supportern.components;
const {
  useAtomValue
} = jotai;
const {
  teamsByFilterAtom
} = svs.supportern.atoms;
const TeamList = () => {
  const teams = useAtomValue(teamsByFilterAtom);
  return React.createElement("div", {
    className: "supportern-team-list padding-top-1 padding-bottom-1"
  }, React.createElement("div", {
    className: "padding-0"
  }, teams.map(team => React.createElement(AtomProvider, {
    key: team.id,
    team: team
  }, React.createElement(TeamItem, null)))));
};
setGlobal('svs.supportern.components.TeamList', TeamList);

 })(window);