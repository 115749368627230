(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/ongoing-list.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/ongoing-list.js');
"use strict";


const {
  TicketListItem
} = svs.supportern.components.composites;
const {
  useAtomValue,
  Provider
} = jotai;
const {
  ticketsAtom,
  ticketAtom
} = svs.supportern.atoms;
const {
  onExtendOngoingGame
} = svs.supportern.utils.tracking;
const OngoingList = () => {
  const tickets = useAtomValue(ticketsAtom);
  return React.createElement(React.Fragment, null, tickets.map(ticket => React.createElement(Provider, {
    initialValues: [[ticketAtom, ticket]],
    key: ticket.id
  }, React.createElement(TicketListItem, {
    isOngoing: true,
    onClick: onExtendOngoingGame
  }))));
};
setGlobal('svs.supportern.components.composites.OngoingList', OngoingList);

 })(window);