(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/head-to-head.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/head-to-head.js');
"use strict";



const {
  Avatar
} = svs.supportern.components;
const {
  Grid,
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  teamAtom
} = svs.supportern.atoms;
const {
  Provider,
  useAtomValue
} = jotai;
const {
  queryClientAtom
} = jotaiTanstackQuery;
const HeadToHead = _ref => {
  var _homeTeam$name, _awayTeam$name;
  let {
    homeTeam,
    awayTeam
  } = _ref;
  const queryClient = useAtomValue(queryClientAtom);
  return React.createElement("div", {
    className: "head-to-head"
  }, React.createElement(Grid, null, React.createElement(GridRow, null, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center",
    xs: 5
  }, React.createElement(Provider, {
    initialValues: [[queryClientAtom, queryClient], [teamAtom, homeTeam]]
  }, React.createElement(Avatar, null))), React.createElement(GridCol, {
    xs: 2
  }), React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center",
    xs: 5
  }, React.createElement(Provider, {
    initialValues: [[queryClientAtom, queryClient], [teamAtom, awayTeam]]
  }, React.createElement(Avatar, null)))), React.createElement(GridRow, {
    className: "head-to-head-text"
  }, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center f-center f-bold",
    xs: 5
  }, (_homeTeam$name = homeTeam === null || homeTeam === void 0 ? void 0 : homeTeam.name) !== null && _homeTeam$name !== void 0 ? _homeTeam$name : 'Hemma'), React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center f-bold f-700",
    xs: 2
  }, "\u2014"), React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center f-center f-bold",
    xs: 5
  }, (_awayTeam$name = awayTeam === null || awayTeam === void 0 ? void 0 : awayTeam.name) !== null && _awayTeam$name !== void 0 ? _awayTeam$name : 'Borta'))));
};
setGlobal('svs.supportern.components.HeadToHead', HeadToHead);

 })(window);