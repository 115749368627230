(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/fallback-favorites-list.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/fallback-favorites-list.js');
"use strict";



const {
  useHistory
} = ReactRouterDOM;
const {
  Avatar
} = svs.supportern.components;
const {
  TicketCardLoader
} = svs.supportern.components.composites;
const {
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  standardCardLayout
} = svs.supportern.utils.tools;
const {
  teamsAtom,
  teamAtom
} = svs.supportern.atoms;
const {
  useAtomValue,
  Provider
} = jotai;
const {
  queryClientAtom
} = jotaiTanstackQuery;
const FallbackFavoritesList = () => {
  const history = useHistory();
  const teams = useAtomValue(teamsAtom);
  const queryClient = useAtomValue(queryClientAtom);
  return React.createElement(GridRow, {
    className: "margin-top-3 margin-bottom-3 u-flex-justify-content-center"
  }, teams.map(team => React.createElement(Provider, {
    initialValues: [[teamAtom, team], [queryClientAtom, queryClient]],
    key: team.id
  }, React.createElement(GridCol, standardCardLayout, React.createElement("a", {
    className: "f-550 margin-bottom-1 block fc-black",
    href: "",
    onClick: e => {
      const {
        TEAM_PATH
      } = svs.supportern.routes;
      e.preventDefault();
      history.push(TEAM_PATH.replace(':id', team.id));
    }
  }, React.createElement(Avatar, null), React.createElement("span", {
    className: "padding-left-1"
  }, team.name)), React.createElement(TicketCardLoader, null)))));
};
setGlobal('svs.supportern.components.composites.FallbackFavoritesList', FallbackFavoritesList);

 })(window);