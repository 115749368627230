(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/avatar.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/avatar.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useHistory
} = ReactRouterDOM;
const {
  getIcon
} = svs.supportern.utils.tools;
const {
  useAtomValue
} = jotai;
const {
  teamAtom
} = svs.supportern.atoms;
const Avatar = _ref => {
  var _team$branding, _team$association$bra;
  let {
    size
  } = _ref;
  const {
    TEAM_PATH
  } = svs.supportern.routes;
  const history = useHistory();
  const team = useAtomValue(teamAtom);
  const logo = team !== null && team !== void 0 && (_team$branding = team.branding) !== null && _team$branding !== void 0 && _team$branding.logo ? team.branding.logo : (_team$association$bra = team === null || team === void 0 ? void 0 : team.association.branding.logo) !== null && _team$association$bra !== void 0 ? _team$association$bra : '';
  const sizes = {
    small: {
      height: '36px',
      width: '36px'
    },
    medium: {},
    large: {
      height: '120px',
      width: '120px'
    }
  };
  const teamLink = svs.core.data.baseUrl + TEAM_PATH.replace(':id', team.id);
  const isTeamPage = window.location.pathname === teamLink;
  const handleClick = e => {
    e.preventDefault();
    if (isTeamPage) {
      return window.scrollTo(0, 0);
    }
    if (svs.supportern.isPlayRoute) {
      return history.push(TEAM_PATH.replace(':id', team.id));
    }
    window.location = teamLink;
  };
  return React.createElement("span", {
    className: "supportern-avatar pointer",
    onClick: handleClick,
    style: _objectSpread(_objectSpread({}, sizes[size]), {}, {
      backgroundImage: logo ? "url(".concat(getIcon(logo), ")") : "url(".concat(svs.core.config.data.svsconfig.cdn, "/images/supportern/avatar-ice-hockey.png)")
    })
  });
};
setGlobal('svs.supportern.components.Avatar', Avatar);

 })(window);