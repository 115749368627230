(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/utils/translation.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/utils/translation.js');
"use strict";

setGlobal('svs.supportern.utils.translation', {
  GENDER: {
    MALE: 'Herrar',
    FEMALE: 'Damer',
    MIXED: 'Blandad'
  },
  CATEGORY: {
    JUNIORS: 'Juniorer',
    SENIORS: 'Seniorer',
    ELITE: 'Elit'
  }
});

 })(window);