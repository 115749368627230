(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/countdown.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/countdown.js');
"use strict";


const {
  differenceInMinutes,
  isSameYear,
  isSameMinute,
  isSameDay,
  isAfter
} = dateFns;
const {
  useAtomValue
} = jotai;
const Icon = svs.ui.ReactIcon;
const {
  drawAtom
} = svs.supportern.atoms;
const getTimeString = _ref => {
  let {
    drawTime,
    serverTime
  } = _ref;
  drawTime = dateFns.parseISO(drawTime);
  if (isSameMinute(drawTime, serverTime) || isAfter(serverTime, drawTime)) {
    return 'Dragning';
  }
  if (differenceInMinutes(drawTime, serverTime) < 60) {
    return "".concat(differenceInMinutes(drawTime, serverTime), " min till dragning");
  }
  if (isSameDay(drawTime, serverTime)) {
    return "Dragning idag kl ".concat(dateFns.format(drawTime, 'HH:mm'));
  }
  let formatStr = 'd/M';
  if (!isSameYear(drawTime, serverTime)) {
    formatStr += ' yyyy';
  }
  return "Dragning ".concat(dateFns.format(drawTime, formatStr));
};
const Countdown = _ref2 => {
  var _useAtomValue;
  let {
    children,
    className = ''
  } = _ref2;
  const draw = (_useAtomValue = useAtomValue(drawAtom)) !== null && _useAtomValue !== void 0 ? _useAtomValue : {};
  if (!draw.drawingTime) {
    return null;
  }
  const now = draw.serverTime ? new Date(draw.serverTime) : new Date();
  return React.createElement("div", {
    className: "u-flex u-flex-align-items-center ".concat(className)
  }, React.createElement(Icon, {
    icon: "clock-simple"
  }), React.createElement("span", {
    className: "supportern-countdown-text"
  }, children ? children : getTimeString({
    drawTime: draw.drawingTime,
    serverTime: now
  })));
};
setGlobal('svs.supportern.components.Countdown', Countdown);

 })(window);