(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/routes/my-tickets/ongoing.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/routes/my-tickets/ongoing.js');
"use strict";


const {
  OngoingList
} = svs.supportern.components.composites;
const {
  FAVORITES_PATH
} = svs.supportern.routes;
const {
  useAtom,
  Provider
} = jotai;
const {
  activeTicketsAtom,
  ticketsAtom
} = svs.supportern.atoms;
const {
  baseUrl
} = svs.core.data;
setGlobal('svs.supportern.routes', {
  ONGOING_PATH: '/',
  Ongoing: () => {
    const [tickets] = useAtom(activeTicketsAtom);
    return React.createElement("div", {
      className: "supportern-constrain"
    }, React.createElement("h2", {
      className: "h2 margin-bottom-1 margin-top-3 padding-bottom f-medium"
    }, "P\xE5g\xE5ende spel"), tickets.length ? React.createElement(Provider, {
      initialValues: [[ticketsAtom, tickets]]
    }, React.createElement(OngoingList, null)) : React.createElement(React.Fragment, null, React.createElement("p", null, "Du har inga p\xE5g\xE5ende spel."), React.createElement("a", {
      href: "".concat(baseUrl, "/").concat(FAVORITES_PATH)
    }, "G\xE5 till dina favoritlag och k\xF6p spel")));
  }
});

 })(window);