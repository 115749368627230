(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/match-card.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/match-card.js');
"use strict";


const {
  Grid,
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  Countdown,
  HeadToHead
} = svs.supportern.components;
const {
  Card
} = svs.ui.ReactCard;
const {
  format
} = dateFns;
const {
  useAtomValue
} = jotai;
const {
  drawAtom
} = svs.supportern.atoms;
const MatchCard = () => {
  const draw = useAtomValue(drawAtom);
  const {
    ownerTeam,
    opponentTeam,
    homeGame,
    salesStart
  } = draw;
  const homeTeam = homeGame ? ownerTeam : opponentTeam;
  const awayTeam = homeGame ? opponentTeam : ownerTeam;
  return React.createElement(Card, {
    className: "padding-1"
  }, React.createElement(Grid, null, React.createElement(GridRow, {
    className: "u-flex u-flex-justify-content-center"
  }, React.createElement(GridCol, {
    md: 4
  }, React.createElement(HeadToHead, {
    awayTeam: awayTeam,
    homeTeam: homeTeam
  }))), React.createElement(GridRow, null, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center"
  }, React.createElement("div", {
    className: "margin-top-1 margin-bottom-1"
  }, React.createElement(Countdown, null, "K\xF6p \xF6ppnar ", format(new Date(salesStart), 'd/M'), " kl. ", format(new Date(salesStart), 'HH:mm')))))));
};
setGlobal('svs.supportern.components.composites.MatchCard', MatchCard);

 })(window);