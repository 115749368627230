(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/atoms/competition-atoms.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/atoms/competition-atoms.js');
"use strict";



const {
  apiController
} = svs.supportern.utils;
const {
  COMPETITIONS
} = svs.supportern.utils.rafflePaths;
const {
  atom
} = jotai;
const {
  atomsWithQuery
} = jotaiTanstackQuery;
const atoms = {
  competitionAtom: atom(),
  competitionsAtom: atomsWithQuery(() => ({
    queryKey: ['competitions'],
    queryFn: async () => {
      const {
        items
      } = await apiController({
        path: COMPETITIONS
      });
      return items;
    }
  }))[0]
};
setGlobal('svs.supportern.atoms', atoms);

 })(window);