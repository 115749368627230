(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/team-header.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/team-header.js');
"use strict";


const {
  useHistory
} = ReactRouterDOM;
const {
  useAtomValue
} = jotai;
const {
  GENDER,
  CATEGORY
} = svs.supportern.utils.translation;
const Icon = svs.ui.ReactIcon;
const {
  Avatar,
  FavIcon
} = svs.supportern.components;
const {
  teamAtom
} = svs.supportern.atoms;
const {
  getIcon
} = svs.supportern.utils.tools;
setGlobal('svs.supportern.components.composites.TeamHeader', () => {
  const {
    ALL_TEAMS_PATH
  } = svs.supportern.routes;
  const history = useHistory();
  const team = useAtomValue(teamAtom);
  const {
    association,
    branding,
    name,
    description,
    category,
    gender,
    sport
  } = team;
  const background = branding !== null && branding !== void 0 && branding.background ? branding.background : association.branding.background;
  const handleOnClickBack = e => {
    e.preventDefault();
    history.push(ALL_TEAMS_PATH);
  };
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "team-header-buttons"
  }, React.createElement("a", {
    className: "supportern-back-button",
    href: "#",
    onClick: handleOnClickBack
  }, React.createElement(Icon, {
    icon: "menu-left"
  })), React.createElement(FavIcon, {
    className: "team-header-favorite-button",
    size: "500"
  })), React.createElement("div", {
    className: "f-center relative opacity-60-navy hero-container"
  }, React.createElement("img", {
    className: "team-hero-image",
    src: getIcon(background)
  }), React.createElement("div", null, React.createElement("div", {
    className: "margin-2 margin-bottom-1 u-flex u-flex-justify-content-center z-index-1"
  }, React.createElement(Avatar, {
    size: "large"
  })), React.createElement("div", {
    className: "padding-1 margin-bottom-1 f-center supportern-max-width centered row fc-white z-index-1 br-12"
  }, React.createElement("div", {
    className: "margin-bottom-1"
  }, React.createElement("div", null, sport.name), React.createElement("h1", {
    className: "f-800 margin-bottom-0"
  }, name), React.createElement("div", null, GENDER[gender] || gender, ' ', CATEGORY[category === null || category === void 0 ? void 0 : category.name.toUpperCase()] || category.name)), React.createElement("p", {
    className: "f-400"
  }, description)))));
});

 })(window);