(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/routes/play/favorites.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/routes/play/favorites.js');
"use strict";


const {
  LoggedInFavorites,
  LoggedOutFavorites
} = svs.supportern.components.composites;
const {
  WidgetArea
} = svs.components.tb_ui_react.widget_area;
const {
  textFavorites
} = svs.supportern.data.config;
setGlobal('svs.supportern.routes', {
  FAVORITES_PATH: '/favorit',
  Favorites: () => {
    const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
    return React.createElement("div", null, React.createElement(WidgetArea, {
      areaName: "favoriteWidget",
      className: "full-width-widget"
    }), React.createElement("div", {
      className: "supportern-constrain"
    }, React.createElement("div", {
      className: "u-border-bottom-1 border-grey-300 padding-bottom-2"
    }, React.createElement("h1", {
      className: "margin-top-2 f-900"
    }, "Mina favoritlag"), React.createElement("p", null, textFavorites)), isLoggedIn ? React.createElement(LoggedInFavorites, null) : React.createElement(LoggedOutFavorites, null)));
  }
});

 })(window);