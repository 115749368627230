(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/team-item.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/team-item.js');
"use strict";


const {
  Avatar,
  FavIcon
} = svs.supportern.components;
const Icon = svs.ui.ReactIcon;
const {
  useHistory
} = ReactRouterDOM;
const {
  useAtomValue
} = jotai;
const {
  teamAtom
} = svs.supportern.atoms;
const TeamItem = () => {
  const {
    TEAM_PATH
  } = svs.supportern.routes;
  const team = useAtomValue(teamAtom);
  const history = useHistory();
  const teamLink = svs.core.data.baseUrl + TEAM_PATH.replace(':id', team.id);
  const isTeamPage = window.location.pathname === teamLink;
  const handleTeamClick = e => {
    e.preventDefault();
    if (isTeamPage) {
      return window.scrollTo(0, 0);
    }
    if (svs.supportern.isPlayRoute) {
      return history.push(TEAM_PATH.replace(':id', team.id));
    }
    window.location = teamLink;
  };
  return React.createElement("div", {
    className: "supportern-team-list-item padding-top-1 padding-bottom-1"
  }, React.createElement("a", {
    className: "fc-black f-550 u-flex u-flex-justify-content-between w100 u-flex-align-items-center f-center",
    onClick: handleTeamClick
  }, React.createElement("div", {
    className: "u-flex-align-self-start"
  }, React.createElement(Avatar, null), React.createElement("span", {
    className: "padding-left-1"
  }, team.name)), React.createElement("div", null, React.createElement(FavIcon, {
    className: "padding-right-1",
    color: "fc-navy"
  }), React.createElement(Icon, {
    className: "u-flex-align-self-end",
    icon: "menu-right",
    size: "100"
  }))));
};
setGlobal('svs.supportern.components.TeamItem', TeamItem);

 })(window);