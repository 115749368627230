(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/draw-list.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/draw-list.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }

const {
  AtomProvider,
  Avatar
} = svs.supportern.components;
const {
  TicketCardChoose
} = svs.supportern.components.composites;
const {
  Grid,
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  standardCardLayout
} = svs.supportern.utils.tools;
const {
  useAtomValue
} = jotai;
const {
  drawsByFilterAtom
} = svs.supportern.atoms;
const {
  CATEGORY
} = svs.supportern.utils.translation;
const {
  useHistory
} = ReactRouterDOM;
const groupDrawsByDate = draws => {
  const drawsByDate = draws.reduce((drawGroups, draw) => {
    const date = draw.drawingTime.split('T')[0];
    if (!drawGroups[date]) {
      drawGroups[date] = [];
    }
    drawGroups[date].push(draw);
    return drawGroups;
  }, {});
  return Object.keys(drawsByDate).map(date => ({
    date,
    draws: drawsByDate[date]
  }));
};
const DrawList = () => {
  const draws = useAtomValue(drawsByFilterAtom);
  const history = useHistory();
  const drawsByDate = groupDrawsByDate(draws);
  return React.createElement(Grid, null, React.createElement(GridRow, {
    className: "margin-bottom-3"
  }, drawsByDate.map(_ref => {
    let {
      date,
      draws
    } = _ref;
    return React.createElement("div", {
      key: date
    }, React.createElement("span", {
      className: "block f-700 f-bold padding-top-1 padding-bottom-2"
    }, dateFns.format(new Date(date), 'EEEE d MMMM', {
      locale: dateFns.locales.sv
    }).replace(/\w/, l => l.toUpperCase())), React.createElement(GridRow, {
      className: "u-flex-justify-content-center"
    }, draws.map(draw => {
      var _draw$ownerTeam$categ;
      return React.createElement(AtomProvider, {
        draw: draw,
        key: draw.id,
        team: draw.ownerTeam
      }, React.createElement(GridCol, _extends({
        className: "margin-bottom-2 margin-top-1"
      }, standardCardLayout), React.createElement("a", {
        className: "f-550 margin-bottom-1 block fc-black u-flex u-flex-align-items-center",
        href: "",
        onClick: e => {
          const {
            TEAM_PATH
          } = svs.supportern.routes;
          e.preventDefault();
          history.push(TEAM_PATH.replace(':id', draw.ownerTeam.id));
        }
      }, React.createElement(Avatar, null), React.createElement("div", {
        className: "padding-left-1 u-flex u-flex-column"
      }, React.createElement("span", {
        className: "fw-100"
      }, draw.ownerTeam.sport.name), React.createElement("span", null, draw.ownerTeam.name), React.createElement("span", {
        className: "fw-100"
      }, CATEGORY[(_draw$ownerTeam$categ = draw.ownerTeam.category) === null || _draw$ownerTeam$categ === void 0 ? void 0 : _draw$ownerTeam$categ.name.toUpperCase()] || draw.ownerTeam.category.name))), React.createElement(TicketCardChoose, null)));
    })));
  }), !draws.length && React.createElement(GridCol, {
    className: "f-center"
  }, "Inga p\xE5g\xE5ende spel")));
};
setGlobal('svs.supportern.components.composites.DrawList', DrawList);

 })(window);