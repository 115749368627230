(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/spinnomatic.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/spinnomatic.js');
"use strict";



const {
  useEffect,
  useRef,
  useState
} = React;
const Button = svs.ui.ReactButton;
const MAX_NUMBER_LENGTH = 6;
const Spinnomatic = _ref => {
  let {
    number,
    onDone,
    totalRows,
    resultsPresented,
    isWin
  } = _ref;
  const numbersLength = String(totalRows).length;
  const [isDone, setIsDone] = useState(resultsPresented);
  const timeline = useRef(gsap.timeline({
    delay: 2
  })).current;
  const numbers = number.padStart(MAX_NUMBER_LENGTH, '0').split('').map(n => parseInt(n, 10));
  const numbersRef = useRef([]);
  const handleSkipClick = () => timeline.progress(1, false);
  const setRef = ref => numbersRef.current.push(ref);
  useEffect(() => {
    for (let i = MAX_NUMBER_LENGTH - numbersLength; i < numbersRef.current.length; i++) {
      const ref = numbersRef.current[i];
      const {
        clientHeight,
        firstChild
      } = ref;
      timeline.to(ref, 3, {
        y: -(clientHeight - firstChild.clientHeight),
        onComplete: () => {
          ref.parentElement.classList.remove('bg-tur-100');
          if (isWin) {
            ref.parentElement.classList.add('bg-green', 'fc-white');
          } else {
            ref.parentElement.classList.add('bg-navy', 'fc-white');
          }
        }
      });
    }
    timeline.add(() => {
      if (!resultsPresented && isWin) {
        svs.tur.confettiCannon();
      }
      setIsDone(true);
      onDone();
    }, '+=2');
    if (isDone) timeline.progress(1, false);
  }, []);
  return React.createElement("div", {
    className: "spinnomatic"
  }, numbers.map((correctNumber, index) =>
  React.createElement("div", {
    className: "spinnomatic-card bg-tur-100 margin-bottom-1 ".concat(MAX_NUMBER_LENGTH - numbersLength > index ? 'disabled-number' : ''),
    key: index
  }, React.createElement("div", {
    className: "spinnomatic-number-container",
    ref: setRef
  }, _.range(0, 11 + correctNumber).map(n => React.createElement("span", {
    className: "spinnomatic-number",
    key: n
  }, n % 10))))), !isDone && React.createElement(Button, {
    block: true,
    onClick: handleSkipClick,
    size: 300
  }, "Visa direkt"));
};
setGlobal('svs.supportern.components.Spinnomatic', Spinnomatic);

 })(window);