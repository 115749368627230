(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/utils/page-title.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/utils/page-title.js');
"use strict";


const {
  useEffect
} = React;
const {
  useLocation
} = ReactRouterDOM;
const {
  matchPath
} = svs.app.ReactRouter;
const {
  useAtomValue
} = jotai;
setGlobal('svs.supportern.utils.PageTitle', () => {
  const {
    teamByIdAtom
  } = svs.supportern.atoms;
  const {
    pathname
  } = useLocation();
  const team = useAtomValue(teamByIdAtom)[0];
  useEffect(() => {
    try {
      const foundPath = Object.keys(svs.core.data.routesMetaData).find(key => matchPath("".concat(window.location.href.includes('mina-supporternspel') ? '/mina-supporternspel' : '').concat(pathname), {
        path: key,
        exact: true,
        strict: false
      }));
      _.templateSettings = {
        interpolate: /\{\{(.+?)\}\}/g
      };
      document.title = _.template(svs.core.data.routesMetaData[foundPath].title)({
        teamName: team === null || team === void 0 ? void 0 : team.name
      });
    } catch (_unused) {
      svs.supportern.logger.error("Failed to set document title at ".concat(pathname));
    }
  }, [pathname]);
  return null;
});

 })(window);