(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/ticket-card-choose.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/ticket-card-choose.js');
"use strict";


const Button = svs.ui.ReactButton;
const {
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  TicketCard
} = svs.supportern.components.composites;
const {
  Countdown
} = svs.supportern.components;
const {
  useHistory
} = ReactRouterDOM;
const {
  useAtomValue
} = jotai;
const {
  drawAtom,
  teamAtom
} = svs.supportern.atoms;
const TicketCardChoose = () => {
  const draw = useAtomValue(drawAtom);
  const team = useAtomValue(teamAtom);
  const history = useHistory();
  const {
    objective,
    drawName,
    type,
    id
  } = draw || {};
  const handleChooseTicket = () => {
    svs.supportern.logger.info('NAVIGATE TO BUY TICKET');
    const {
      TEAM_PATH
    } = svs.supportern.routes;
    history.push(TEAM_PATH.replace(':id', team.id));
  };
  return React.createElement(TicketCard, null, !id && React.createElement(GridRow, null, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center"
  }, React.createElement("p", {
    className: "padding-4",
    style: {
      marginBottom: '30px'
    }
  }, "Inget p\xE5g\xE5ende spel"))), type === 'EVENT' && drawName && React.createElement(GridRow, null, React.createElement(GridCol, {
    className: "margin-2 margin-bottom-0 u-flex u-flex-justify-content-center u-flex-wrap u-flex-align-items-center"
  }, React.createElement("div", {
    className: "h3 margin-half"
  }, drawName), React.createElement("span", {
    className: "f-center"
  }, objective))), id && React.createElement(GridRow, {
    gutter: "0"
  }, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center",
    md: 12
  }, React.createElement("div", {
    className: "margin-top-1 margin-bottom-1"
  }, React.createElement(Countdown, null)))), React.createElement(GridRow, {
    className: "margin-top-auto",
    gutter: "0"
  }, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center",
    md: 12
  }, React.createElement(Button, {
    block: true,
    disabled: !id,
    focus: true,
    onClick: handleChooseTicket
  }, "K\xF6p spel"))));
};
setGlobal('svs.supportern.components.composites.TicketCardChoose', TicketCardChoose);

 })(window);