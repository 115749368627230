(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/atom-provider.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/atom-provider.js');
"use strict";



const {
  Provider,
  useSetAtom,
  useAtomValue
} = jotai;
const {
  queryClientAtom
} = jotaiTanstackQuery;
const {
  teamAtom,
  teamsAtom,
  drawAtom,
  drawsAtom,
  drawsFilterAtom
} = svs.supportern.atoms;
const {
  useEffect,
  useRef
} = React;
const AtomSetter = _ref => {
  let {
    team,
    teams,
    draw,
    draws,
    children
  } = _ref;
  const setTeam = useSetAtom(teamAtom);
  const setTeams = useSetAtom(teamsAtom);
  const setDraw = useSetAtom(drawAtom);
  const setDraws = useSetAtom(drawsAtom);
  const childrenRef = useRef(null);
  const debuga = svs.supportern.data.debuga;
  useEffect(() => {
    if (debuga) {
      gsap.fromTo(childrenRef.current, 2, {
        opacity: 0.5
      }, {
        opacity: 1
      });
    }
  }, [team, draw, draws]);
  useEffect(() => {
    if (debuga) {
      childrenRef.current.classList.add(...childrenRef.current.children[0].className.split(' '));
      childrenRef.current.children[0].className = '';
    }
  }, []);
  useEffect(() => {
    setTeam(team);
  }, [team]);
  useEffect(() => {
    setTeams(teams);
  }, [teams]);
  useEffect(() => {
    setDraw(draw);
  }, [draw]);
  useEffect(() => {
    setDraws(draws);
  }, [draws]);
  const hoverDebug = () => {
    svs.supportern.logger.info('Team', team);
    svs.supportern.logger.info('Teams', teams);
    svs.supportern.logger.info('Draw', draw);
    svs.supportern.logger.info('Draws', draws);
  };
  return React.createElement(React.Fragment, null, debuga ? React.createElement("div", {
    onMouseEnter: hoverDebug,
    ref: childrenRef,
    style: {
      border: '2px solid red'
    }
  }, children) : children);
};
const AtomProvider = _ref2 => {
  let {
    team,
    teams,
    draw,
    draws,
    drawsFilter,
    children
  } = _ref2;
  const queryClient = useAtomValue(queryClientAtom);
  return React.createElement(Provider, {
    initialValues: [[teamAtom, team], [teamsAtom, teams], [drawAtom, draw], [drawsAtom, draws], [drawsFilterAtom, drawsFilter], [queryClientAtom, queryClient]]
  }, React.createElement(AtomSetter, {
    draw: draw,
    draws: draws,
    team: team,
    teams: teams
  }, children));
};
setGlobal('svs.supportern.components.AtomProvider', AtomProvider);

 })(window);