(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/ticket-card-loader.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/ticket-card-loader.js');
"use strict";


const {
  TicketCard
} = svs.supportern.components.composites;
const {
  useEffect,
  useRef
} = React;
const TicketCardLoader = () => {
  const containerRef = useRef();
  useEffect(() => {
    const loader = new svs.ui.Loader(containerRef.current).show(300);
    return () => {
      loader.hide();
    };
  }, []);
  return React.createElement("div", {
    className: "relative",
    ref: containerRef
  }, React.createElement(TicketCard, null, React.createElement("div", {
    className: "u-flex u-flex-justify-content-center u-flex-align-items-center",
    style: {
      height: 190
    }
  }, "Laddar...")), React.createElement("div", {
    className: "card-opacity-overlay"
  }));
};
setGlobal('svs.supportern.components.composites.TicketCardLoader', TicketCardLoader);

 })(window);