(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/routes/play/popular.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/routes/play/popular.js');
"use strict";



const {
  DrawList,
  League
} = svs.supportern.components.composites;
const {
  PrefetchTeamsByCompetition
} = svs.supportern.components;
const {
  competitionsAtom,
  teamsFilterAtom,
  competitionAtom
} = svs.supportern.atoms;
const {
  useAtomValue,
  Provider
} = jotai;
const {
  queryClientAtom
} = jotaiTanstackQuery;
const {
  textPopular
} = svs.supportern.data.config;
const {
  WidgetArea
} = svs.components.tb_ui_react.widget_area;
setGlobal('svs.supportern.routes', {
  POPULAR_PATH: '/populara',
  Popular: () => {
    const competitions = useAtomValue(competitionsAtom);
    const queryClient = useAtomValue(queryClientAtom);
    return React.createElement(React.Fragment, null, React.createElement(WidgetArea, {
      areaName: "popularWidget",
      className: "full-width-widget"
    }), React.createElement("div", {
      className: "supportern-constrain"
    }, React.createElement("div", {
      className: " u-border-bottom-1 border-grey-300 padding-bottom-2"
    }, React.createElement("h1", {
      className: "margin-top-2 f-900"
    }, "Popul\xE4ra lotterier"), React.createElement("p", null, textPopular)), competitions.map(competition => React.createElement(Provider, {
      initialValues: [[competitionAtom, competition], [teamsFilterAtom, {
        competitionId: competition.id
      }], [queryClientAtom, queryClient]],
      key: competition.id
    }, React.createElement("div", {
      className: "u-border-bottom-1 border-grey-300"
    }, React.createElement(League, null, React.createElement(PrefetchTeamsByCompetition, {
      drawsFilter: "sortBy=soldTickets&sortDirection=DESC&status=ACTIVE&type=MATCH"
    }, React.createElement(DrawList, null))))))));
  }
});

 })(window);