(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/receipt.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/receipt.js');
"use strict";


const {
  useAtomValue
} = jotai;
const {
  ticketAtom
} = svs.supportern.atoms;
const Receipt = () => {
  const ticket = useAtomValue(ticketAtom);
  const {
    DRAW_TYPES
  } = svs.supportern.utils.tools;
  const {
    draw,
    prize,
    buy,
    ticketsInfo,
    firstPurchaseTime
  } = ticket;
  const ticketNumbersRanges = ticketsInfo.map(_ref => {
    let {
      numbersRange
    } = _ref;
    return numbersRange;
  });
  const {
    type,
    id: drawId,
    drawingTime,
    ownerTeam: {
      name
    },
    status
  } = draw;
  const {
    amount: {
      amount: totalWin = 0
    } = {}
  } = prize;
  const {
    price: {
      amount: totalCost
    }
  } = buy;
  const isActive = status === 'ACTIVE';
  const gameType = type === DRAW_TYPES.MATCH ? 'Matchspel' : 'Eventspel';
  const title = "Supportern - ".concat(gameType);
  const numberOfTickets = ticketNumbersRanges.reduce((acc, _ref2) => {
    let {
      from,
      to
    } = _ref2;
    return acc + (to - from) + 1;
  }, 0);
  const pricePerGame = totalCost / numberOfTickets;
  return React.createElement("table", {
    className: "table table-default table-bordered table-col-bordered margin-top-2"
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", {
    colSpan: "2"
  }, title))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, React.createElement("span", {
    className: "f-bold"
  }, "Betalt: "), React.createElement("span", {
    className: "right"
  }, svs.utils.date.ShortDateAndTime(firstPurchaseTime))), React.createElement("td", null, React.createElement("span", {
    className: "f-bold"
  }, "Insats per spel: "), React.createElement("span", {
    className: "right"
  }, "".concat(svs.utils.format.Currency(pricePerGame), " kr")))), React.createElement("tr", null, React.createElement("td", null, React.createElement("span", {
    className: "f-bold"
  }, isActive ? 'Dragning sker' : 'Avgjordes', ":", ' '), React.createElement("span", {
    className: "right"
  }, svs.utils.date.ShortDateAndTime(drawingTime))), React.createElement("td", null, React.createElement("span", {
    className: "f-bold"
  }, "Total insats: "), React.createElement("span", {
    className: "right"
  }, "".concat(svs.utils.format.Currency(totalCost), " kr")))), React.createElement("tr", null, React.createElement("td", {
    colSpan: "2"
  }, React.createElement("span", {
    className: "f-bold"
  }, "F\xF6rening: "), name)), React.createElement(svs.utils.reactHelpUtils.components.AuthenticatedCustomerService, null, React.createElement("tr", null, React.createElement("td", {
    colSpan: "2"
  }, React.createElement("span", {
    className: "f-bold"
  }, "Omg\xE5ngsId: "), drawId))), React.createElement("tr", {
    className: "table-footer"
  }, React.createElement("td", {
    colSpan: "2"
  }, React.createElement("span", {
    className: "f-bold"
  }, "Spelat via: "), "Internet")), !isActive && React.createElement("tr", null, React.createElement("td", {
    colSpan: "2",
    style: {
      textAlign: 'center'
    }
  }, React.createElement("span", {
    className: "f-bold"
  }, "Vinst: "), "".concat(svs.utils.format.Currency(totalWin), " kr")))));
};
setGlobal('svs.supportern.components.Receipt', Receipt);

 })(window);