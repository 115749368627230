(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/favorites-list.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/favorites-list.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }

const {
  useHistory
} = ReactRouterDOM;
const {
  Avatar,
  AtomProvider
} = svs.supportern.components;
const {
  TicketCardChoose
} = svs.supportern.components.composites;
const {
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  standardCardLayout
} = svs.supportern.utils.tools;
const {
  drawsByFilterAtom,
  teamsAtom
} = svs.supportern.atoms;
const {
  CATEGORY
} = svs.supportern.utils.translation;
const {
  useAtomValue
} = jotai;
const FavoritesList = () => {
  const history = useHistory();
  const teams = useAtomValue(teamsAtom);
  const draws = useAtomValue(drawsByFilterAtom);
  if (!teams.length) {
    return React.createElement("p", {
      className: "margin-top-3 margin-bottom-3 f-center"
    }, "Inga favoritlag valda");
  }
  const favoriteDraws = [];
  for (const team of teams) {
    const filteredDraws = draws.filter(draw => draw.ownerTeam.id === team.id);
    if (filteredDraws.length) {
      favoriteDraws.push(...filteredDraws);
    } else {
      favoriteDraws.push({
        ownerTeam: team
      });
    }
  }
  return React.createElement(GridRow, {
    className: "margin-top-3 margin-bottom-3 u-flex-justify-content-center"
  }, favoriteDraws.map(draw => {
    var _draw$ownerTeam$categ;
    return React.createElement(AtomProvider, {
      draw: draw,
      key: draw.id || draw.ownerTeam.id,
      team: draw.ownerTeam
    }, React.createElement(GridCol, _extends({}, standardCardLayout, {
      className: "margin-bottom-3"
    }), React.createElement("a", {
      className: "f-550 margin-bottom-1 block fc-black u-flex u-flex-align-items-center",
      href: "",
      onClick: e => {
        const {
          TEAM_PATH
        } = svs.supportern.routes;
        e.preventDefault();
        history.push(TEAM_PATH.replace(':id', draw.ownerTeam.id));
      }
    }, React.createElement(Avatar, null), React.createElement("div", {
      className: "padding-left-1 u-flex u-flex-column"
    }, React.createElement("span", {
      className: "fw-100"
    }, draw.ownerTeam.sport.name), React.createElement("span", null, draw.ownerTeam.name), React.createElement("span", {
      className: "fw-100"
    }, CATEGORY[(_draw$ownerTeam$categ = draw.ownerTeam.category) === null || _draw$ownerTeam$categ === void 0 ? void 0 : _draw$ownerTeam$categ.name.toUpperCase()] || draw.ownerTeam.category.name))), React.createElement(TicketCardChoose, null)));
  }));
};
setGlobal('svs.supportern.components.composites.FavoritesList', FavoritesList);

 })(window);