(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/routes/play/team.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/routes/play/team.js');
"use strict";

function _extends() { return _extends = Object.assign ? Object.assign.bind() : function (n) { for (var e = 1; e < arguments.length; e++) { var t = arguments[e]; for (var r in t) ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]); } return n; }, _extends.apply(null, arguments); }


const {
  RaffleInformation,
  Socials,
  AtomProvider,
  LowTicketsDisclaimer,
  TeamErrorBoundry
} = svs.supportern.components;
const {
  TicketCardBuy,
  TeamHeader,
  MatchCard
} = svs.supportern.components.composites;
const {
  standardCardLayout,
  DRAW_TYPES,
  STATUS
} = svs.supportern.utils.tools;
const {
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  drawAtom,
  teamByIdAtom,
  drawsByFilterAtom
} = svs.supportern.atoms;
const {
  useAtomValue,
  Provider
} = jotai;
const {
  queryClientAtom
} = jotaiTanstackQuery;
setGlobal('svs.supportern.routes', {
  TEAM_PATH: '/alla-lag/:id',
  Team: () => {
    const team = useAtomValue(teamByIdAtom)[0];
    const draws = useAtomValue(drawsByFilterAtom);
    const queryClient = useAtomValue(queryClientAtom);
    const matchDraws = draws.filter(_ref => {
      let {
        type,
        status
      } = _ref;
      return type === DRAW_TYPES.MATCH && status === STATUS.ACTIVE;
    });
    const eventDraws = draws.filter(_ref2 => {
      let {
        type,
        status
      } = _ref2;
      return type === DRAW_TYPES.EVENT && status === STATUS.ACTIVE;
    });
    const upcomingMatchDraws = draws.filter(_ref3 => {
      let {
        type,
        status
      } = _ref3;
      return type === DRAW_TYPES.MATCH && status === STATUS.PENDING;
    });
    const hasMatchDraws = Boolean(matchDraws.length);
    const hasEventDraws = Boolean(eventDraws.length);
    if (!hasMatchDraws) {
      matchDraws.push({
        id: 0,
        ownerTeam: team.id
      });
    }
    return React.createElement(TeamErrorBoundry, null, React.createElement(AtomProvider, {
      team: team
    }, React.createElement(TeamHeader, null), React.createElement("div", {
      className: "supportern-constrain margin-top-3"
    }, React.createElement(GridRow, {
      className: "u-flex-justify-content-center"
    }, React.createElement("h3", {
      className: "f-700 f-center f-bold margin-1",
      id: "matchspel"
    }, "Matchspel"), matchDraws.map(draw => {
      var _draw$results;
      return React.createElement(AtomProvider, {
        draw: draw,
        key: draw.id,
        team: team
      }, React.createElement(GridCol, _extends({}, standardCardLayout, {
        className: "margin-bottom-2"
      }), React.createElement(TicketCardBuy, null), ((_draw$results = draw.results) === null || _draw$results === void 0 ? void 0 : _draw$results.stats.totalRows) < 6 && React.createElement(LowTicketsDisclaimer, null), hasMatchDraws && React.createElement(RaffleInformation, null)));
    }), hasEventDraws && React.createElement("h3", {
      className: "f-700 f-center f-bold u-border-top-2 border-grey-300 padding-top-3 margin-top-2 margin-bottom-1",
      id: "eventspel"
    }, "Eventspel"), eventDraws.map(draw => {
      var _draw$results2;
      return React.createElement(AtomProvider, {
        draw: draw,
        key: draw.id,
        team: team
      }, React.createElement(GridCol, standardCardLayout, React.createElement(TicketCardBuy, null), ((_draw$results2 = draw.results) === null || _draw$results2 === void 0 ? void 0 : _draw$results2.stats.totalRows) < 6 && React.createElement(LowTicketsDisclaimer, null), React.createElement(RaffleInformation, null)));
    })), React.createElement(Socials, null), React.createElement("h3", {
      className: "f-700 f-center f-bold"
    }, "Kommande lotterier"), React.createElement(GridRow, null, upcomingMatchDraws.length ? upcomingMatchDraws.sort((a, b) => new Date(a.salesStart) - new Date(b.salesStart)).map(draw => React.createElement(Provider, {
      initialValues: [[drawAtom, draw], [queryClientAtom, queryClient]],
      key: draw.id
    }, React.createElement(GridCol, {
      className: "margin-top-2",
      md: 12
    }, React.createElement("p", {
      className: "f-550"
    }, dateFns.format(new Date(draw.salesStart), 'EEEE d MMMM', {
      locale: dateFns.locales.sv
    }).replace(/\w/, l => l.toUpperCase())), React.createElement(MatchCard, null)))) : React.createElement("div", {
      className: "margin-bottom-2 f-center"
    }, "Inga kommande dragningar")))));
  }
});

 })(window);