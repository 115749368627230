(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/logged-in-favorites.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/logged-in-favorites.js');
"use strict";



const {
  AtomProvider
} = svs.supportern.components;
const {
  FavoritesList,
  League,
  FallbackFavoritesList
} = svs.supportern.components.composites;
const {
  standardCardLayout,
  mergeCompetitionsWithTeams
} = svs.supportern.utils.tools;
const {
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const Button = svs.ui.ReactButton;
const {
  useHistory
} = ReactRouterDOM;
const Icon = svs.ui.ReactIcon;
const {
  Suspense
} = React;
const {
  teamsByFilterAtom,
  competitionsAtom,
  competitionAtom,
  teamsAtom
} = svs.supportern.atoms;
const {
  useAtomValue,
  Provider
} = jotai;
const {
  queryClientAtom
} = jotaiTanstackQuery;
setGlobal('svs.supportern.components.composites.LoggedInFavorites', () => {
  const history = useHistory();
  const teams = useAtomValue(teamsByFilterAtom);
  const competitions = useAtomValue(competitionsAtom);
  const queryClient = useAtomValue(queryClientAtom);
  const competitionsWithTeams = mergeCompetitionsWithTeams(competitions, teams);
  const hasFavoriteTeams = competitionsWithTeams.some(_ref => {
    let {
      teams
    } = _ref;
    return teams.length;
  });
  const handleButtonClick = () => history.push(svs.supportern.routes.ALL_TEAMS_PATH);
  return React.createElement(React.Fragment, null, React.createElement(GridRow, null, React.createElement(GridCol, null, hasFavoriteTeams ? competitionsWithTeams.filter(_ref2 => {
    let {
      teams
    } = _ref2;
    return teams.length;
  }).map(competition => React.createElement(Provider, {
    initialValues: [[competitionAtom, competition], [teamsAtom, competition.teams], [queryClientAtom, queryClient]],
    key: competition.id
  }, React.createElement("div", {
    className: "favorite-teams-item u-border-bottom-1 border-grey-300"
  }, React.createElement(League, {
    expandable: false,
    isExpanded: true
  }, React.createElement(Suspense, {
    fallback: React.createElement(FallbackFavoritesList, null)
  }, React.createElement(AtomProvider, {
    drawsFilter: "sortBy=salesEnd&status=ACTIVE",
    teams: competition.teams
  }, React.createElement(FavoritesList, null))))))) : React.createElement("div", {
    className: "u-flex u-flex-justify-content u-flex-align-items f-center u-flex-column margin-top-2"
  }, React.createElement(Icon, {
    icon: "favorites",
    size: "900"
  }), React.createElement("h2", {
    className: "margin-2 f-700"
  }, "Inga favoritlag valda")))), React.createElement(GridRow, {
    className: "u-flex u-flex-justify-content-center padding-top-1 padding-bottom-1"
  }, React.createElement(GridCol, standardCardLayout, React.createElement(Button, {
    block: true,
    className: "margin-bottom-2 margin-top-1",
    inverted: true,
    onClick: handleButtonClick,
    size: 300
  }, React.createElement(Icon, {
    icon: "favorites"
  }), " L\xE4gg till favoritlag"))));
});

 })(window);