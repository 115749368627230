(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/ball-menu.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/ball-menu.js');
"use strict";


const {
  useHistory,
  useLocation
} = ReactRouterDOM;
const {
  ItemsMenu,
  IconItem
} = svs.ui.ReactItemsMenu;
const {
  baseUrl
} = svs.core.data;
setGlobal('svs.supportern.components.BallMenu', _ref => {
  let {
    location = useLocation(),
    history = useHistory(),
    menuItems
  } = _ref;
  return React.createElement(ItemsMenu, {
    branding: "neutral",
    centered: true,
    useScrollFaders: true,
    useScrollHelper: true
  }, menuItems.map(_ref2 => {
    let {
      label,
      icon,
      path
    } = _ref2;
    return React.createElement(IconItem, {
      alignment: "vertical",
      Component: "a",
      gutter: 1,
      href: baseUrl + path,
      iconName: icon,
      isSelected: path === '/' ? path === location.pathname : new RegExp("^".concat(path), 'i').test(location.pathname),
      key: path,
      label: label,
      onClick: e => {
        e.preventDefault();
        history.push(path);
      }
    });
  }));
});

 })(window);