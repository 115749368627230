(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/routes/play/all-teams.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/routes/play/all-teams.js');
"use strict";


const {
  TeamList
} = svs.supportern.components;
const {
  League
} = svs.supportern.components.composites;
const {
  useAtomValue,
  Provider
} = jotai;
const {
  competitionsAtom,
  competitionAtom,
  teamsFilterAtom
} = svs.supportern.atoms;
const {
  textAllTeams
} = svs.supportern.data.config;
const {
  WidgetArea
} = svs.components.tb_ui_react.widget_area;
setGlobal('svs.supportern.routes', {
  ALL_TEAMS_PATH: '/alla-lag',
  AllTeams: () => {
    const competitions = useAtomValue(competitionsAtom);
    return React.createElement(React.Fragment, null, React.createElement(WidgetArea, {
      areaName: "allTeamsWidget",
      className: "full-width-widget"
    }), React.createElement("div", {
      className: "supportern-constrain"
    }, React.createElement("div", {
      className: "u-border-bottom-1 border-grey-300 padding-bottom-2"
    }, React.createElement("h1", {
      className: "margin-top-2 f-900"
    }, "Alla lag"), React.createElement("p", null, textAllTeams)), React.createElement("div", null, competitions.map(competition => React.createElement(Provider, {
      initialValues: [[competitionAtom, competition], [teamsFilterAtom, {
        competitionId: competition.id
      }]],
      key: competition.id
    }, React.createElement("div", {
      className: "u-border-bottom-1 border-grey-300"
    }, React.createElement(League, null, React.createElement(TeamList, null))))))));
  }
});

 })(window);