(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/routes/my-tickets/detail.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/routes/my-tickets/detail.js');
"use strict";



const {
  useEffect,
  useRef,
  useState
} = React;
const {
  useAtom,
  Provider
} = jotai;
const Button = svs.ui.ReactButton;
const {
  Grid,
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  BubbleInfo
} = svs.tur.bubbleInfo.components;
const {
  BubbleWinMessage
} = svs.tur.bubbleWinMessage.components;
const formatCurrency = svs.utils.format.Currency;
const {
  onResultPresented
} = svs.supportern.utils.tracking;
const {
  TEAM_PATH
} = svs.supportern.routes;
const {
  teamAtom,
  drawAtom,
  ticketAtom,
  ticketByIdAtom,
  ticketIdAtom
} = svs.supportern.atoms;
const {
  RaffleInformation,
  Spinnomatic,
  Ticket,
  Receipt
} = svs.supportern.components;
const {
  WinningTicket
} = svs.supportern.components.composites;
const {
  getIcon,
  standardCardLayout,
  DRAW_TYPES
} = svs.supportern.utils.tools;
const {
  TICKET_PRIZE_CHECKED,
  TICKET_PRIZE_CHECKED_TOKEN
} = svs.supportern.utils.rafflePaths;
const ANIMATION_STATES = {
  NOT_STARTED: 'NOT_STARTED',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED'
};
setGlobal('svs.supportern.routes', {
  DETAIL_PATH: '/avgjorda/:id',
  DETAIL_PATH_GUID: '/avgjorda',
  Detail: () => {
    const [ticket] = useAtom(ticketByIdAtom);
    const {
      draw,
      ticketsInfo,
      id
    } = ticket;
    let {
      resultsPresented
    } = ticket;
    if (svs.utils.paramHandler.get({
      key: 'uncorrect'
    }).value) {
      resultsPresented = false;
    }
    const [animationState, setAnimationState] = useState(resultsPresented ? ANIMATION_STATES.FINISHED : ANIMATION_STATES.NOT_STARTED);
    const ticketNumbersRanges = ticketsInfo.map(_ref => {
      let {
        numbersRange
      } = _ref;
      return numbersRange;
    });
    const preCorrectionRef = useRef(null);
    const {
      ownerTeam,
      results,
      type
    } = draw;
    const gameType = type === DRAW_TYPES.MATCH ? 'matchspel' : 'eventspel';
    const {
      totalRows,
      poolSize: {
        amount: poolAmount
      }
    } = results.stats;
    const {
      amount
    } = ticket.prize;
    const winAmount = amount === null || amount === void 0 ? void 0 : amount.amount;
    const {
      number
    } = draw.results.prizeCategories[0].winners[0].winnerData;
    const {
      background
    } = ownerTeam.association.branding;
    const onStartCorrection = () => {
      gsap.to(preCorrectionRef.current, {
        opacity: 0,
        duration: 1.5,
        onComplete: () => {
          setAnimationState(ANIMATION_STATES.STARTED);
        }
      });
    };
    const ticketRevealTL = useRef(gsap.timeline()).current;
    useEffect(() => {
      ticketRevealTL.to('.zoom-out', {
        scale: 0,
        opacity: 0,
        display: 'none',
        duration: 2
      });
      ticketRevealTL.fromTo('.zoom-in', {
        scale: 0,
        display: 'none'
      }, {
        scale: 1,
        display: 'block',
        duration: 2
      });
      ticketRevealTL.pause();
      if (resultsPresented) {
        ticketRevealTL.progress(1, false);
      }
    }, []);
    useEffect(() => {
      if (animationState === ANIMATION_STATES.FINISHED) {
        if (!resultsPresented) {
          ticketRevealTL.resume();
          const guid = svs.utils.paramHandler.get({
            key: 'guid'
          }).value;
          const path = guid ? TICKET_PRIZE_CHECKED_TOKEN.replace(':token', guid) : TICKET_PRIZE_CHECKED.replace(':id', id);
          onResultPresented(winAmount, draw.ownerTeam.sport.name);
          svs.supportern.utils.apiController({
            path,
            options: {
              method: 'POST'
            }
          });
        }
      }
    }, [animationState]);
    const handleBuyBtnClick = () => {
      const {
        baseUrl
      } = svs.core.data;
      window.location = "".concat(baseUrl, "/").concat(TEAM_PATH.replace(':id', ownerTeam.id));
    };
    const Won = () => React.createElement(BubbleWinMessage, {
      options: {
        product: '',
        amount: winAmount
      }
    }, React.createElement("div", null, React.createElement("p", null, "Du har vunnit", ' ', React.createElement("span", {
      className: "f-bold"
    }, formatCurrency(poolAmount), ' ', "kr"), "."), React.createElement("p", null, React.createElement("span", {
      className: "f-bold"
    }, ownerTeam.name), ' ', "\xE4r lika stora vinnare. De f\xE5r ocks\xE5", ' ', React.createElement("span", {
      className: "f-bold"
    }, formatCurrency(poolAmount), ' ', "kr"), ", tack vare Supportern.")));
    const Loss = () => React.createElement(BubbleInfo, {
      className: "bubble-info-container",
      header: "Tyv\xE4rr vann du inte i dragningen."
    }, React.createElement("p", null, "Men", ' ', ownerTeam.name, ' ', "\xE4r en vinnare \xE4nd\xE5. Genom Supportern har du och m\xE5nga andra hj\xE4lpt f\xF6reningen med fina slantar."));
    return React.createElement(Provider, {
      initialValues: [[drawAtom, draw], [teamAtom, ownerTeam], [ticketAtom, ticket], [ticketIdAtom, id]]
    }, React.createElement(Grid, null, React.createElement("div", {
      className: animationState === ANIMATION_STATES.FINISHED ? 'draw-finished' : ''
    }, React.createElement("div", {
      className: "f-center relative opacity-60-navy hero-container"
    }, React.createElement("img", {
      className: "team-hero-image",
      src: getIcon(background)
    }), React.createElement("div", {
      className: "supportern-constrain relative"
    }, React.createElement(GridRow, {
      className: "winning-number ".concat(animationState === ANIMATION_STATES.NOT_STARTED ? 'invisible' : '')
    }, React.createElement(GridCol, null, React.createElement("h1", {
      className: "f-center f-900 fc-white"
    }, "Vinstnummer"))), animationState === ANIMATION_STATES.NOT_STARTED && React.createElement("div", {
      ref: preCorrectionRef
    }, React.createElement(GridRow, null, React.createElement(GridCol, {
      className: "margin-bottom-2"
    }, React.createElement(GridRow, null, React.createElement(GridCol, null, React.createElement("div", {
      className: "u-flex u-flex-justify-content-center"
    }, React.createElement(BubbleInfo, {
      className: "bubble-info-container",
      header: "Dags f\xF6r dragning!"
    }, React.createElement("p", null, "Vinstchans f\xF6r dig:", React.createElement("span", {
      className: "f-bold"
    }, " ", formatCurrency(poolAmount), " kr"), React.createElement("br", null), ownerTeam.name, " f\xE5r alltid:", React.createElement("span", {
      className: "f-bold"
    }, " ", formatCurrency(poolAmount), " kr")))))), React.createElement(Button, {
      block: true,
      className: "margin-top-1",
      focus: true,
      onClick: onStartCorrection
    }, "Visa dragning")))), (animationState === ANIMATION_STATES.STARTED || animationState === ANIMATION_STATES.FINISHED) && React.createElement(GridRow, null, React.createElement(GridCol, {
      className: "u-flex u-flex-justify-content-center margin-bottom-2"
    }, React.createElement(Spinnomatic, {
      isWin: Boolean(winAmount),
      number: number || '000',
      onDone: () => {
        setAnimationState(ANIMATION_STATES.FINISHED);
      },
      resultsPresented: Boolean(resultsPresented),
      totalRows: totalRows
    }))), animationState === ANIMATION_STATES.FINISHED && React.createElement(GridRow, null, React.createElement(GridCol, {
      className: "u-flex u-flex-justify-content-center margin-bottom-2"
    }, winAmount ? React.createElement(Won, null) : React.createElement(Loss, null))))), React.createElement(GridRow, null, React.createElement(GridCol, {
      className: "relative"
    }, React.createElement("div", {
      className: "supportern-constrain  ".concat(animationState === ANIMATION_STATES.FINISHED ? 'supportern-detail-after-game' : 'supportern-detail')
    }, React.createElement(GridRow, null, React.createElement(GridCol, {
      className: "u-flex u-flex-justify-content-center"
    }, React.createElement("span", {
      className: "f-550 fc-black f-center supportern-ticket-list-title zoom-out margin-1"
    }, "Mina ", gameType))), React.createElement(GridRow, {
      className: "supportern-ticket-list zoom-out"
    }, ticketNumbersRanges.map(_ref2 => {
      let {
        from,
        to
      } = _ref2;
      return _.range(from, to + 1).map(ticketNr => React.createElement(GridCol, {
        className: "u-flex u-flex-justify-content-center",
        key: ticketNr,
        md: 2,
        sm: 4,
        style: {
          marginTop: 0
        },
        xs: 6
      }, React.createElement(Ticket, {
        className: "filter-shadow-100",
        ticketNr: ticketNr
      })));
    })), React.createElement(GridRow, {
      className: "u-flex u-flex-justify-content-center margin-bottom-2 margin-top-2"
    }, React.createElement(GridCol, standardCardLayout, React.createElement("div", {
      className: "zoom-in"
    }, React.createElement(WinningTicket, null)), React.createElement(Button, {
      block: true,
      className: "zoom-in",
      focus: true,
      onClick: handleBuyBtnClick,
      size: 300
    }, "K\xF6p nytt spel till n\xE4sta match"))), React.createElement(GridRow, {
      className: "u-flex u-flex-justify-content-center"
    }, React.createElement(GridCol, standardCardLayout, React.createElement(RaffleInformation, {
      className: "zoom-out"
    }))), React.createElement(GridRow, {
      className: "u-flex u-flex-justify-content-center"
    }, React.createElement(GridCol, standardCardLayout, React.createElement(RaffleInformation, {
      className: "zoom-in",
      isSettled: true
    }))), React.createElement("div", {
      className: "zoom-in"
    }, React.createElement(GridRow, null, React.createElement(GridCol, {
      className: "u-flex u-flex-justify-content-center"
    }, React.createElement("span", {
      className: "f-550 fc-black f-center supportern-ticket-list-title zoom-out margin-1"
    }, "Mina ", gameType))), React.createElement(GridRow, {
      className: "supportern-ticket-list"
    }, ticketNumbersRanges.map(_ref3 => {
      let {
        from,
        to
      } = _ref3;
      return _.range(from, to + 1).map(ticketNr => React.createElement(GridCol, {
        className: "u-flex u-flex-justify-content-center",
        key: ticketNr,
        md: 2,
        sm: 4,
        style: {
          marginTop: 0
        },
        xs: 6
      }, React.createElement(Ticket, {
        className: "filter-shadow-100",
        ticketNr: ticketNr
      })));
    }))), React.createElement(GridRow, {
      className: "u-flex u-flex-justify-content-center"
    }, React.createElement(GridCol, standardCardLayout, React.createElement("div", {
      className: "zoom-in"
    }, React.createElement(Receipt, null))))))))));
  }
});

 })(window);