(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/atoms/ticket-atoms.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/atoms/ticket-atoms.js');
"use strict";



const {
  apiController
} = svs.supportern.utils;
const {
  TICKETS,
  TICKET_ID,
  TICKET_ID_TOKEN
} = svs.supportern.utils.rafflePaths;
const {
  atom
} = jotai;
const {
  atomsWithQuery
} = jotaiTanstackQuery;
const ticketAtoms = {
  ticketAtom: atom(),
  ticketsAtom: atom(),
  ticketIdAtom: atom(),
  activeTicketsAtom: atomsWithQuery(() => ({
    queryKey: ['tickets', 'active'],
    queryFn: async () => {
      const {
        items
      } = await apiController({
        path: "".concat(TICKETS, "?status=ACTIVE&sortBy=salesEnd&sortDirection=ASC")
      });
      return items;
    }
  }))[0],
  paidTicketsAtom: atomsWithQuery(() => ({
    queryKey: ['tickets', 'paid'],
    queryFn: async () => {
      const {
        items
      } = await apiController({
        path: "".concat(TICKETS, "?status=PAID&status=REFUNDED&status=PRIZE_CHECKED&sortBy=salesEnd&sortDirection=DESC")
      });
      return items;
    }
  }))[0],
  ticketByIdAtom: atomsWithQuery(get => ({
    queryKey: ['ticket', get(ticketAtoms.ticketIdAtom)],
    queryFn: async _ref => {
      var _svs$utils$paramHandl;
      let {
        queryKey: [, ticketId]
      } = _ref;
      let path;
      const guid = (_svs$utils$paramHandl = svs.utils.paramHandler.get({
        key: 'guid'
      })) === null || _svs$utils$paramHandl === void 0 ? void 0 : _svs$utils$paramHandl.value;
      if (!ticketId && !guid) {
        svs.supportern.logger.warn('ticketByIdAtom requires a ticketId Provider');
      }
      if (guid) {
        path = TICKET_ID_TOKEN.replace(':token', guid);
      } else {
        path = TICKET_ID.replace(':id', ticketId);
      }
      const ticket = await apiController({
        path
      });
      return ticket;
    }
  }))[0]
};
setGlobal('svs.supportern.atoms', ticketAtoms);

 })(window);