(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/ticket.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/ticket.js');
"use strict";



const formatCurrency = svs.utils.format.Currency;
const {
  Avatar
} = svs.supportern.components;
const {
  useEffect,
  useRef
} = React;
const {
  teamAtom,
  drawAtom
} = svs.supportern.atoms;
const {
  useAtomValue
} = jotai;
const MINIMUM_SOLD_TICKETS = 6;
const Ticket = _ref => {
  var _draw$results$stats$t, _draw$results, _draw$results$stats$p, _draw$results2, _ref2, _branding, _team$association;
  let {
    branding,
    children,
    className = '',
    style = {},
    ticketNr = 0,
    isStub = false,
    isCancelled = false
  } = _ref;
  const team = useAtomValue(teamAtom);
  const draw = useAtomValue(drawAtom);
  const totalTickets = (_draw$results$stats$t = draw === null || draw === void 0 || (_draw$results = draw.results) === null || _draw$results === void 0 || (_draw$results = _draw$results.stats) === null || _draw$results === void 0 ? void 0 : _draw$results.totalRows) !== null && _draw$results$stats$t !== void 0 ? _draw$results$stats$t : 0;
  const amount = (_draw$results$stats$p = draw === null || draw === void 0 || (_draw$results2 = draw.results) === null || _draw$results2 === void 0 || (_draw$results2 = _draw$results2.stats) === null || _draw$results2 === void 0 || (_draw$results2 = _draw$results2.poolSize) === null || _draw$results2 === void 0 ? void 0 : _draw$results2.amount) !== null && _draw$results$stats$p !== void 0 ? _draw$results$stats$p : 0;
  branding = (_ref2 = (_branding = branding) !== null && _branding !== void 0 ? _branding : team === null || team === void 0 || (_team$association = team.association) === null || _team$association === void 0 ? void 0 : _team$association.branding) !== null && _ref2 !== void 0 ? _ref2 : {};
  const amountRef = useRef(null);
  const ticketsRef = useRef(null);
  const currentAmountRef = useRef({
    val: amount
  });
  const currentTotalTicketsRef = useRef({
    val: totalTickets
  });
  const ticketRef = useRef(null);
  const hasEnoughTickets = totalTickets >= MINIMUM_SOLD_TICKETS;
  const {
    status: drawStatus
  } = draw || {};
  const isActive = drawStatus === 'ACTIVE';

  useEffect(() => {
    if (branding.color) {
      ticketRef.current.style.setProperty('--ticket-branding', branding.color);
    }
    if (branding.color || branding.colorSecondary) {
      var _branding$secondColor;
      ticketRef.current.style.setProperty('--ticket-branding-secondary', (_branding$secondColor = branding.secondColor) !== null && _branding$secondColor !== void 0 ? _branding$secondColor : branding.color);
    }
    if (branding.colorBottom) {
      ticketRef.current.style.setProperty('--ticket-color-bottom', branding.colorBottom);
    }
  }, []);
  useEffect(() => {
    gsap.to(currentAmountRef.current, 1.5, {
      val: amount,
      ease: 'Power2.easeOut',
      onUpdate: () => {
        if (amountRef.current) amountRef.current.innerText = formatCurrency(Math.round(currentAmountRef.current.val));
      }
    });
    gsap.to(currentTotalTicketsRef.current, 1.5, {
      val: totalTickets,
      ease: 'Power2.easeOut',
      onUpdate: () => {
        if (ticketsRef.current) ticketsRef.current.innerText = formatCurrency(Math.round(currentTotalTicketsRef.current.val));
      }
    });
  }, [amount]);
  if (!ticketNr) className += ' supportern-ticket-short';
  if (isStub) className += ' supportern-ticket-stub';
  return React.createElement("div", {
    className: "supportern-ticket supportern-ripped-top supportern-ripped-bottom f-center ".concat(className),
    ref: ticketRef,
    style: style
  }, children, !children && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "supportern-ticket-top padding-1"
  }, React.createElement(Avatar, null)), React.createElement("div", {
    className: "supportern-ticket-middle padding-half bg-white"
  }, Boolean(ticketNr) && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "f-550 padding-bottom-half padding-top-half"
  }, "nr ", React.createElement("span", {
    className: "f-800"
  }, "".concat(ticketNr).padStart(6, '0'))), !isStub && React.createElement("div", {
    className: "bg-grey-300 margin-bottom-half",
    style: {
      height: '1px'
    }
  })), !isStub && React.createElement("div", {
    className: "".concat(isCancelled ? 'invisible' : '')
  }, React.createElement("span", null, isActive ? 'Vinstsumma just nu' : 'Vinstchans'), React.createElement("div", {
    className: "f-800 padding-top-half"
  }, React.createElement("span", {
    ref: amountRef
  }, hasEnoughTickets ? formatCurrency(amount) : 0), React.createElement("span", {
    className: "f-400"
  }, "\xA0kr")))), !isStub && !ticketNr && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "bg-white"
  }, React.createElement("div", {
    className: "bg-grey-300 margin-left-1 margin-right-1",
    style: {
      height: '1px'
    }
  })), React.createElement("div", {
    className: "supportern-ticket-bottom padding-half bg-white"
  }, React.createElement("span", {
    className: "".concat(hasEnoughTickets ? '' : 'invisible')
  }, React.createElement("span", {
    ref: ticketsRef
  }, formatCurrency(totalTickets)), " spel s\xE5lda")))));
};
setGlobal('svs.supportern.components.Ticket', Ticket);

 })(window);