(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/prefetch-teams-by-competition.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/prefetch-teams-by-competition.js');
"use strict";



const {
  useAtomValue,
  Provider
} = jotai;
const {
  queryClientAtom
} = jotaiTanstackQuery;
const {
  teamsByFilterAtom,
  drawsFilterAtom
} = svs.supportern.atoms;
setGlobal('svs.supportern.components.PrefetchTeamsByCompetition', _ref => {
  let {
    children,
    drawsFilter
  } = _ref;
  const teams = useAtomValue(teamsByFilterAtom);
  const queryClient = useAtomValue(queryClientAtom);
  return React.createElement(Provider, {
    initialValues: [[queryClientAtom, queryClient], [drawsFilterAtom, teams.reduce((acc, curr) => "".concat(acc, "&teamId=").concat(curr.id), drawsFilter)]]
  }, children);
});

 })(window);