(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/team-error-boundry.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/team-error-boundry.js');
"use strict";


const {
  Button,
  ReactIcon: Icon
} = svs.ui;
const {
  ErrorBoundary
} = svs.components.errorBoundary;
const ErrorMessage = () => {
  const {
    ALL_TEAMS_PATH
  } = svs.supportern.routes;
  return React.createElement("div", {
    className: "align-center error-screen padding-3"
  }, React.createElement(Icon, {
    icon: "exclamation-sign",
    size: "900"
  }), React.createElement("h1", {
    className: "f-900 margin-v-1"
  }, "N\xE5got gick fel"), React.createElement("p", {
    className: "f-500 margin-bottom-2"
  }, "Vi kunde inte hitta laget du letade efter. Prova leta i alla lag listan."), React.createElement(Button, {
    className: "margin-bottom-1 qa-try-again",
    href: svs.core.data.baseOriginalUrl + ALL_TEAMS_PATH,
    inverted: true
  }, "G\xE5 till alla lag"));
};
const TeamErrorBoundry = _ref => {
  let {
    children
  } = _ref;
  return React.createElement(ErrorBoundary, {
    fallback: ErrorMessage
  }, children);
};
setGlobal('svs.supportern.components.TeamErrorBoundry', TeamErrorBoundry);

 })(window);