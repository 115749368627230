(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/low-tickets-disclaimer.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/low-tickets-disclaimer.js');
"use strict";

const Icon = svs.ui.ReactIcon;
setGlobal('svs.supportern.components.LowTicketsDisclaimer', () => React.createElement("div", {
  className: "margin-top-2 low-disclaimer"
}, React.createElement(Icon, {
  className: "margin-right-1",
  icon: "exclamation-sign",
  size: 300
}), React.createElement("span", null, "Vinstsumman och detaljerad information om s\xE5lda spel uppdateras n\xE4r fler \xE4n sex spel s\xE5lts.")));

 })(window);