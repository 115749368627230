(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/fav-icon.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/fav-icon.js');
"use strict";


const Icon = svs.ui.ReactIcon;
const {
  paramHandler
} = svs.utils;
const {
  useAtomValue
} = jotai;
const {
  teamAtom
} = svs.supportern.atoms;
const {
  useSetAtom
} = jotai;
const {
  favoriteMutationAtom
} = svs.supportern.atoms;
const {
  onFavoriteTeam
} = svs.supportern.utils.tracking;
const FavIcon = _ref => {
  let {
    color = 'icon-inverted',
    className = '',
    size
  } = _ref;
  const team = useAtomValue(teamAtom);
  const {
    favorite,
    id
  } = team;
  const mutate = useSetAtom(favoriteMutationAtom);

  const {
    value
  } = paramHandler.get({
    key: 'fav'
  });
  if (value && value === id) {
    paramHandler.remove({
      key: 'fav'
    });
    if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      mutate([{
        team
      }]);
    }
  }
  const handleFavClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
      paramHandler.set({
        key: 'fav',
        value: id
      });
      svs.components.customer_login.login({
        returnNewCustomer: true
      });
      return;
    }
    onFavoriteTeam(team.name);
    mutate([{
      team
    }]);
  };
  return React.createElement("a", {
    className: className,
    onClick: handleFavClick
  }, React.createElement(Icon, {
    color: color,
    icon: "favorites".concat(favorite ? '-filled' : ''),
    size: size
  }));
};
setGlobal('svs.supportern.components.FavIcon', FavIcon);

 })(window);